<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-main">
				<div class="mhome-address" @click="handleAddressChange">
					<div class="address-icon"><img src="@/assets/images/item/icon_address.png" alt=""></div>
					<div class="address-main" v-if="addressInfo.addr_id>0">
						<div class="name">{{addressInfo.address}}</div>
						<div class="text">{{addressInfo.consignee}}<span>{{addressInfo.phone_mob}}</span></div>
					</div>
					<div class="address-main" v-else>
						<div class="name"><span>{{$t('mobile.order.selectAddress')}}</span></div>
					</div>
					<div class="address-next"><i class="el-icon-arrow-right"></i></div>
				</div>
				<div class="mhome-store" v-for="(item,index) in orderGoods" :key="index">
					<div class="store-head">{{item.store_name}}</div>
					<div class="store-goods" v-for="(goods,index2) in item.goods_list" :key="index2">
						<div class="item-pic"><img :src="goods.goods_image" @error="imageLoadError" alt=""></div>
						<div class="item-main">
							<div class="name">{{goods.goods_name}}</div>
							<div class="text">{{goods.specification}}</div>
						</div>
						<div class="item-box">
							<div class="price">￥<span>{{goods.price | priceFilter()}}</span></div>
							<div class="number">x{{goods.quantity}}</div>
						</div>
					</div>
					<div class="mhome-layout">
						<div class="item" @click="handlePopOpenShipping(item)">
							<div class="item-label">{{$t('mobile.order.deliveryType')}}</div>
							<div class="item-block">{{orderShipping[item.store_id]?orderShipping[item.store_id].shipping_name:$t('mobile.order.shopDelivery')}}</div>
							<div class="item-next"><i class="el-icon-arrow-right"></i></div>
						</div>
						<!-- <div class="item" @click="handlePopOpenPayment(item)">
							<div class="item-label">{{$t('mobile.order.payType')}}</div>
							<div class="item-block">{{orderPayment[item.store_id]?orderPayment[item.store_id].payment_name:$t('mobile.order.onlinePay')}}</div>
							<div class="item-next"><i class="el-icon-arrow-right"></i></div>
						</div> -->
						<div class="item" @click="handlePopOpenCoupon(item)" v-if="item.coupon_list.length>0">
							<div class="item-label">{{$t('mobile.order.shopCoupon')}}</div>
							<div class="item-block" v-if="orderCoupon[item.store_id]">{{$t('mobile.order.couponText1')}}{{parseInt(orderCoupon[item.store_id].quota)}}{{$t('mobile.order.couponText2')}}{{parseInt(orderCoupon[item.store_id].money)}}{{$t('mobile.order.couponText3')}}</div>
							<div class="item-block" v-else>{{$t('mobile.order.unuse')}}</div>
							<div class="item-next"><i class="el-icon-arrow-right"></i></div>
						</div>
						<div class="item" @click="handlePopOpenRemark(item)">
							<div class="item-label">{{$t('mobile.order.remark')}}</div>
							<div class="item-block line-clamp">
								<template v-if="orderRemark[item.store_id] && orderRemark[item.store_id].content">
									{{orderRemark[item.store_id].content}}
								</template>
								<span v-else>{{$t('mobile.order.unremark')}}</span>
							</div>
							<div class="item-next"><i class="el-icon-arrow-right"></i></div>
						</div>
					</div>
				</div>
				<div class="mhome-details" v-if="totalCoupon > 0">
					<div class="details-head">{{$t('mobile.order.priceDetail')}}</div>
					<div class="mhome-layout">
						<div class="item">
							<div class="item-label">{{$t('mobile.order.totalPrice')}} <span>{{$t('mobile.order.totalText1')}}{{orderInfo.all_total_num}}{{$t('mobile.order.totalText2')+$t('mobile.order.totalText3')}}</span></div>
							<div class="item-block"><div class="price" v-if="orderInfo.all_total_price>0">￥<span>{{ orderInfo.all_total_price | priceFilter() }}</span></div></div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('mobile.order.shopCoupon')}}
								<template v-for="(item,index) in Object.keys(orderCoupon)">
									<span :key="index" v-if="index<1 && orderCoupon[item]">{{$t('mobile.order.couponText1')}}{{parseInt(orderCoupon[item].quota)}}{{$t('mobile.order.couponText2')}}{{parseInt(orderCoupon[item].money)}}{{$t('mobile.order.couponText3')}}{{Object.keys(orderCoupon).length>1?$t('mobile.unit.etc'):''}}</span>
								</template>
							</div>
							<div class="item-block"><div class="price red">{{$t('mobile.order.reduce')}}￥<span>{{ totalCoupon | priceFilter() }}</span></div></div>
							<div class="item-next"><i class="el-icon-arrow-right"></i></div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('mobile.order.total')}}</div>
							<div class="item-block"><div class="price" v-if="orderInfo.all_total_price>0">￥<span>{{ orderInfo.all_total_price - totalCoupon | priceFilter() }}</span></div></div>
						</div>
					</div>
				</div>
			</div>
			<div class="mhome-block"></div>
			<div class="mhome-bottom">
				<div class="mfooter-nav">
					<div class="text"><span>{{$t('mobile.order.totalText1')}}{{orderInfo.all_total_num}}{{$t('mobile.order.totalText2')}}</span></div>
					<div class="text">{{$t('mobile.order.total')+':'}}</div>
					<div class="price" v-if="orderInfo.all_total_price>0">￥<span>{{ orderInfo.all_total_price - totalCoupon  | priceFilter() }}</span></div>
					<div class="btn" @click="handleSubmit" v-loading="isSubmit">{{$t('mobile.actions.submitOrder')}}</div>
				</div>
			</div>
			<!-- 弹窗-配送方式 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.shipping}">
				<div class="mpop-bg" @click="handlePopClose('shipping')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('shipping')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.deliveryType')}}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popShippingCheck">
							<template v-if="popShipping.length>0">
								<el-radio v-for="(item,index) in popShipping" :key="index" :label="JSON.stringify(item)">{{item.shipping_name}}</el-radio>
							</template>
							<el-radio label="" v-else>{{$t('mobile.order.shopDelivery')}}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleOrderShipping">{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
			<!-- 弹窗-配送方式 end -->
			<!-- 弹窗-支付方式 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.payment}">
				<div class="mpop-bg" @click="handlePopClose('payment')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('payment')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.payType')}}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popPaymentCheck">
							<el-radio v-for="(item,index) in popPayment" :key="index" :label="JSON.stringify(item)">{{item.payment_name}}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleOrderPayment">{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
			
			<!-- <div class="mpop" :class="{'mpop-show':isPopShow.alipay}">
				<div class="mpop-bg"></div>
				<div class="mpop-body">
					<div class="mpop-close left-close" @click="handleAlipayClose"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.fictitious')}}</div>
					<div class="mpop-alipay">
						<div class="mpop-price">￥<span>{{ orderInfo.all_total_price - totalCoupon | priceFilter()}}</span></div>
						<ul class="mpop-paytype">
							<li>
								<div class="item-icon"><svg-icon icon-class="money_bag_fill"></svg-icon></div>
								<div class="item-block">
									<div class="name">{{ $t('mobile.order.alipayText') }}</div>
									<div class="text">{{ $t('mobile.order.alipayDesc') }}</div>
								</div>
								<div class="item-next"><i class="el-icon-arrow-right"></i></div>
							</li>
						</ul>
					</div>
					<div class="mpop-bottom">
						<div class="btn blue-btn" @click="handleOrderAlipay">{{ $t('mobile.actions.confirmTrade') }}</div>
					</div>
				</div>
			</div>
			<div class="mpop" :class="{'mpop-show':isPopShow.cipher}">
				<div class="mpop-bg"></div>
				<div class="mpop-cipher">
					<div class="mpop-close left-close" @click="handlePopClose('cipher')"><i class="el-icon-close"></i></div>
					<div class="cipher-head">{{ $t('mobile.order.alipayTip') }}</div>
					<div class="cipher-main">
						<div class="cipher-input">
							<el-input v-model="payCipher" maxlength="6" type="tel" ref="paycipher" @input="changeCipher"></el-input>
						</div>
						<div class="cipher-box" @click="handleInput">
							<div class="item" :class="{ active: payCipher.length > 0 }"></div>
							<div class="item" :class="{ active: payCipher.length > 1 }"></div>
							<div class="item" :class="{ active: payCipher.length > 2 }"></div>
							<div class="item" :class="{ active: payCipher.length > 3 }"></div>
							<div class="item" :class="{ active: payCipher.length > 4 }"></div>
							<div class="item" :class="{ active: payCipher.length > 5 }"></div>
						</div>
					</div>
				</div>
			</div> -->
			<!-- 弹窗-支付方式 end -->
			<!-- 弹窗-店铺优惠 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.coupon}">
				<div class="mpop-bg" @click="handlePopClose('coupon')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('coupon')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.shopCoupon')}}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popCouponCheck">
							<el-radio v-for="(item,index) in popCoupon" :key="index" :label="JSON.stringify(item)">{{item.name}}<span>{{$t('mobile.order.couponText1')}}{{parseInt(item.quota)}}{{$t('mobile.order.couponText2')}}{{parseInt(item.money)}}{{$t('mobile.order.couponText3')}}</span></el-radio>
							<el-radio label="">{{$t('mobile.order.unuse')}}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleOrderCoupon">{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
			<!-- 弹窗-店铺优惠 end -->
			<!-- 弹窗-订单备注 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.remark}">
				<div class="mpop-bg" @click="handlePopClose('remark')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('remark')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.remark')}}</div>
					<div class="mpop-remark">
						<div class="remark-wrap">
							<textarea class="remark-text" v-model="popRemarkText" rows="6" maxlength="200" :placeholder="$t('mobile.placeholder.orderRemark')"></textarea>
							<div class="remark-tip"><span>{{popRemarkText.length}}</span>/200</div>
						</div>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleOrderRemark">{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
			<!-- 弹窗-订单备注 end -->
		</div>
	</div>
</template>

<script>
	import { fetchAddress, confirmOrder, createOrder } from '@/api/mobile/order';
	export default {
		data() {
			return {
				addressInfo: {},
				orderInfo: {},
				orderGoods: [],
				orderShipping: {},
				orderPayment: {},
				orderRemark: {},
				orderCoupon: {},
				totalCoupon: '',
				isPopShow: {
					shipping: false,
					payment: false,
					remark: false,
					coupon: false,
					alipay: false,
					cipher: false
				},
				checkId: 0,
				popShipping: [],
				popShippingCheck: '',
				popPayment: [],
				popPaymentCheck: '',
				popRemarkText: '',
				popCoupon: [],
				popCouponCheck: '',
				isSubmit: false,
				payCipher: '',
				payOrder: []
			}
		},
		mounted() {
			this.getAddressInfo();
			this.getOrderInfo();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getOrderInfo(){
				let that = this;
				if(that.$route.query.from=='detail' && parseInt(that.$route.query.quantity)<1){
					that.$message({
						message: that.$t('mobile.errorMsg.orderNumber'),
						customClass: 'wap-message',
						type: 'error',
						onClose: function(){
							that.$router.go(-1);
						}
					});
					return false;
				}
				let loading = that.$loading();
				let param = {
					...that.$route.query,
					app_key: that.$store.getters.appKey
				}
				confirmOrder(param).then(response => {
					const { data } = response
					loading.close()
					that.orderInfo = data
					that.orderGoods = data.list
					that.getPaymentType(data.payments)
					that.onOrderAction(data.list)
				}).catch(() => {
					loading.close();
					that.$router.go(-1);
				})
			},
			getAddressInfo(){
				let param = {
					addr_id: this.$route.query.addr_id,
					uuid: this.$store.getters.uuid
				}
				fetchAddress(param).then(response => {
					this.addressInfo = response.data || {};
				})
			},
			getPaymentType(data) {
				this.popPayment = data
				this.popPayment.forEach(item => {
					if(item.payment_code === 'alipay') {
						this.popPaymentCheck = JSON.stringify(item)
					}
				});
				if(!this.popPaymentCheck) {
					this.popPaymentCheck = JSON.stringify(this.popPayment[0])
				}
			},
			onOrderAction(data){
				let order_shipping = {};
				let order_payment = {};
				let order_coupon = {};
				let coupon_total = 0;
				data.forEach(item=>{
					order_shipping[item.store_id] = item.ship_list?item.ship_list[0]:{};
					order_payment[item.store_id] = item.payment_list?item.payment_list[0]:{};
					order_coupon[item.store_id] = item.coupon_list?item.coupon_list[0]:{};
					let money = order_coupon[item.store_id]?order_coupon[item.store_id].money:0
					coupon_total+=money
				})
				this.orderShipping = order_shipping;
				this.orderCoupon = order_coupon;
				this.totalCoupon = coupon_total;
			},
			handleAddressChange(){
				this.$router.push({
					name: 'mOrderAddress',
					query: this.$route.query
				})
			},
			handlePopOpenShipping(item){
				this.checkId = item.store_id;
				this.popShipping = item.ship_list;
				this.popShippingCheck = this.orderShipping[item.store_id]?JSON.stringify(this.orderShipping[item.store_id]):'';
				this.isPopShow.shipping = true;
			},
			handleOrderShipping(){
				this.orderShipping[this.checkId] = JSON.parse(this.popShippingCheck);
				this.isPopShow.shipping = false;
			},
			handlePopOpenCoupon(item){
				this.checkId = item.store_id;
				this.popCoupon = item.coupon_list;
				this.popCouponCheck = this.orderCoupon[item.store_id]?JSON.stringify(this.orderCoupon[item.store_id]):'';
				this.isPopShow.coupon = true;
			},
			handleOrderCoupon(){
				this.orderCoupon[this.checkId] = this.popCouponCheck?JSON.parse(this.popCouponCheck):'';
				let coupon_total = 0;
				for(let key in this.orderCoupon){
					let money = this.orderCoupon[key]?this.orderCoupon[key].money:0
					coupon_total+=money
				}
				this.totalCoupon = coupon_total;
				this.isPopShow.coupon = false;
			},
			handlePopOpenRemark(item){
				if(this.orderRemark[item.store_id] && this.orderRemark[item.store_id].content){
					this.popRemarkText = this.orderRemark[item.store_id].content;
				}else {
					this.popRemarkText = '';
				}
				this.checkId = item.store_id;
				this.isPopShow.remark = true;
			},
			handleOrderRemark(){
				this.orderRemark[this.checkId] = {
					content: this.popRemarkText,
				}
				this.isPopShow.remark = false;
			},
			handlePopClose(type){
				if(type === 'payment') {
					this.$confirm(this.$t('mobile.confirm.waiverPay'),'',{
						confirmButtonText: this.$t('mobile.actions.continuePay'),
						cancelButtonText: this.$t('mobile.actions.waiver'),
						customClass: 'wap-message-confirm',
						showClose: false,
						closeOnClickModal: false
					}).then(() => { }).catch(() => {
						this.$router.push({name: "mOrderList"});
					})
				} else {
					this.isPopShow[type] = false;
				}
			},
			// handlePopOpenPayment(item){
			// 	this.checkId = item.store_id;
			// 	this.popPayment = item.payment_list;
			// 	this.popPaymentCheck = this.orderPayment[item.store_id]?JSON.stringify(this.orderPayment[item.store_id]):'';
			// 	this.isPopShow.payment = true;
			// },
			// handleOrderAlipay() {
			// 	this.isPopShow.cipher = true;
			// 	this.$nextTick(() => {
			// 		this.$refs.paycipher.focus()
			// 	})
			// },
			// handleAlipayClose() {
			// 	this.$confirm(this.$t('mobile.confirm.waiverPay'),'',{
			// 		confirmButtonText: this.$t('mobile.actions.continuePay'),
			// 		cancelButtonText: this.$t('mobile.actions.waiver'),
			// 		customClass: 'wap-message-confirm',
			// 		showClose: false,
			// 		closeOnClickModal: false
			// 	}).then(() => { }).catch(() => {
			// 		this.$router.push({name: "mOrderList"});
			// 	})
			// },
			// handleInput() {
			// 	this.$refs.paycipher.focus()
			// },
			// changeCipher(value) {
			// 	if (value.length > 5) {
			// 		paymentOrder({order_sn: this.payOrder.join(',')}).then(() => {
			// 			this.$message({
			// 				message: this.$t('mobile.successMsg.payment'),
			// 				customClass: 'wap-message',
			// 				type: 'success'
			// 			});
			// 			this.$router.push({name: "mOrderMsg"});
			// 		})
			// 	}
			// },
			handleSubmit(){
				if(!this.addressInfo.addr_id>0){
					this.$message({
						message: this.$t('mobile.errorMsg.selectAddress'),
						customClass: 'wap-message',
						type: 'error'
					});
					return false;
				}
				this.isSubmit = true
				const paymentOption = JSON.parse(this.popPaymentCheck)
				let param = {
					...this.$route.query,
					address_id: this.addressInfo.addr_id,
					payment: JSON.stringify(this.orderPayment),
					remark: JSON.stringify(this.orderRemark),
					shipping: JSON.stringify(this.orderShipping),
					coupon: JSON.stringify(this.orderCoupon),
					school_payment_id: paymentOption.school_payment_id
				}
				createOrder(param).then(response => {
					const { data } = response
					this.payOrder = data.list
					this.isSubmit = false
					this.isPopShow.payment = true
				})
			},
			async handleOrderPayment(){
				this.isPopShow.payment = false
				const paymentOption = JSON.parse(this.popPaymentCheck)
				const params = {
					order_sn: this.payOrder.join(','),
					payment_code: paymentOption.payment_code
				}
				const { data } = await this.$store.dispatch('payment/alipay', params)
				if(paymentOption.payment_code === 'alipay') { // 支付宝支付
					const div = document.createElement('div')
					div.innerHTML = data.data
					document.body.appendChild(div)
					document.forms['alipaysubmit'].submit()
					document.body.removeChild(div)
				} else {
					this.$message({
						message: this.$t('mobile.successMsg.payment'),
						customClass: 'wap-message',
						type: 'success'
					});
					this.$router.push({name: "mOrderMsg"});
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mhome-main {
		padding: 0.32rem 0.192rem;

		.mhome-address {
			display: flex;
			align-items: center;
			padding: 0.384rem;
			border-radius: 0.256rem;
			background-color: #FFFFFF;
			margin-bottom: 0.256rem;

			.address-icon {
				width: 0.768rem;
				height: 0.768rem;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.address-main {
				flex: 1;
				width: 0;
				margin: 0 0.32rem;

				.name {
					font-weight: bold;
					font-size: 0.448rem;
					line-height: 0.704rem;
					span {
						color: #777777;
					}
				}

				.text {
					color: #666666;
					font-size: 0.352rem;
					line-height: 0.544rem;
					margin-top: 0.16rem;

					span {
						margin-left: 0.256rem;
						color: #999999;
					}
				}
			}

			.address-next {
				color: #999999;
				font-size: 0.448rem;
			}
		}
		.mhome-store {
			padding: 0.256rem 0.384rem;
			border-radius: 0.256rem;
			background-color: #FFFFFF;
			margin-bottom: 0.256rem;
			.store-head {
				font-size: 0.448rem;
				font-weight: bold;
				height: 0.768rem;
				line-height: 0.768rem;
			}
			.store-goods {
				display: flex;
				padding: 0.192rem 0;
				.item-pic {
					width: 2.56rem;
					height: 2.56rem;
					img {
						width: 100%;
						height: 100%;
						border-radius: 0.256rem;
						object-fit: cover;
					}
				}
				.item-main {
					flex: 1;
					width: 0;
					margin: 0 0.32rem;
					.name {
						font-size: 0.416rem;
						height: 0.64rem;
						line-height: 0.64rem;
						overflow: hidden;
						margin-bottom: 0.128rem;
					}
					.text {
						color: #999999;
						font-size: 0.384rem;
						line-height: 0.544rem;
					}
				}
				.item-box {
					text-align: right;
					.price {
						font-size: 0.32rem;
						height: 0.64rem;
						line-height: 0.64rem;
						margin-bottom: 0.128rem;
						span {
							font-size: 0.416rem;
						}
					}
					.number {
						color: #999999;
						font-size: 0.384rem;
						height: 0.544rem;
						line-height: 0.544rem;
					}
				}
			}
		}
		.mhome-layout {
			.item {
				display: flex;
				align-items: center;
				padding: 0.192rem 0;
				line-height: 0.672rem;
				.item-label {
					min-width: 2.688rem;
					font-size: 0.416rem;
					margin-right: 0.32rem;
					span {
						color: #999999;
						font-size: 0.352rem;
						margin-left: 0.192rem;
					}
				}
				.item-block {
					width: 0;
					flex: 1;
					font-size: 0.416rem;
					text-align: right;
					span {
						color: #999999;
					}
					.price {
						color: #333333;
						font-weight: bold;
						font-size: 0.384rem;
						span {
							color: #333333;
							font-size: 0.448rem;
						}
					}
					.red {
						color: #fa842c;
						span {
							color: #fa842c;
						}
					}
				}
				.line-clamp {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.item-next {
					color: #999999;
					font-size: 0.448rem;
				}
			}
		}
		.mhome-details {
			padding: 0.256rem 0.384rem;
			border-radius: 0.256rem;
			background-color: #FFFFFF;
			margin-bottom: 0.256rem;
			.details-head {
				font-size: 0.448rem;
				font-weight: bold;
				height: 0.768rem;
				line-height: 0.768rem;
				margin-bottom: 0.192rem;
			}
		}
	}

	.mhome-block {
		height: 1.472rem;
	}

	.mfooter-nav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: #FFFFFF;
		box-shadow: 0 -1px 1px #E5E5E5;
		padding: 0.16rem 0.384rem;

		.text {
			font-weight: bold;
			font-size: 0.416rem;
			color: #333333;
			margin-right: 0.096rem;

			span {
				color: #999999;
			}
		}

		.price {
			color: #fa842c;
			font-size: 0.416rem;
			margin-right: 0.256rem;

			span {
				font-weight: bold;
				font-size: 0.576rem;
			}
		}

		.btn {
			height: 1.152rem;
			line-height: 1.152rem;
			width: 3.2rem;
			text-align: center;
			font-size: 0.448rem;
			display: inline-block;
			vertical-align: top;
			border-radius: 0.576rem;
			color: #FFFFFF;
			font-weight: bold;
			border-radius: 0.576rem;
			background: linear-gradient(to right, #ee7e32, #f25528);
		}
	}
	.mpop-group {
		padding: 0.256rem 0.384rem;
		::v-deep .el-radio-group {
			display: block;
			.el-radio {
				display: block;
				margin-right: 0;
				padding: 8px 0;
				.el-radio__inner {
					width: 0.512rem;
					height: 0.512rem;
				}
				.el-radio__inner:after {
					width: 0.192rem;
					height: 0.192rem;
				}
				.el-radio__label {
					color: #333333;
					font-size: 0.416rem;
					span {
						color: #999999;
						margin-left: 0.192rem;
						font-size: 0.384rem;
					}
				}
			}
		}
	}
	.mpop-remark {
		padding: 0 0.384rem;
		.remark-wrap {
			padding: 0.256rem 0.32rem;
			border-radius: 0.192rem;
			background-color: #f1f2f3;
			
			.remark-text {
				width: 100%;
				padding: 0;
				font-size: 0.416rem;
				line-height: 0.544rem;
				resize: none;
			}
			.remark-tip {
				text-align: right;
				font-size: 0.384rem;
				line-height: 0.384rem;
				margin-top: 0.192rem;
				span {
					color: #999999;
				}
			}
		}
	}
	.mpop-alipay {
		height: 8rem;
		.mpop-price {
			text-align: center;
			font-size: 0.576rem;
			padding: 0.32rem 0;
			margin-bottom: 0.32rem;
			span {
				font-size: 0.96rem;
			}
		}
		.mpop-paytype {
			margin: 0 0.32rem;
			border-top: 1px solid #E5E5E5;
			li {
				display: flex;
				padding: 0.256rem 0;
				justify-content: space-between;
				border-bottom: 1px solid #E5E5E5;
				.item-icon {
					color: #ff0005;
					font-size: 0.512rem;
				}
				.item-block {
					flex: 1;
					margin-left: 0.32rem;
					.name {
						font-size: 0.416rem;
						line-height: 0.544rem;
					}
					.text {
						color: #999999;
						font-size: 0.352rem;
						line-height: 0.544rem;
						margin-top: 0.128rem;
					}
				}
				.item-next {
					line-height: 0.544rem;
					color: #999999;
					font-size: 0.448rem;
				}
			}
		}
	}
	
	.mpop-bottom {
		.blue-btn {
			border-radius: 0.32rem;
			background: $--uucolor-primary;
		}
	}
	.mpop-cipher {
		top: 50%;
		left: 10%;
		width: 80%;
		padding: 0.64rem;
		box-sizing: border-box;
		border-radius: 0.32rem;
		background-color: #FFFFFF;
		position: absolute;
		transform: translateY(-50%);
		.cipher-head {
			font-size: 0.48rem;
			line-height: 0.64rem;
			font-weight: 600;
			text-align: center;
			padding-top: 0.96rem;
		}
		.cipher-main {
			padding: 0.64rem 0;
			.cipher-input {
				height: 0;
				overflow: hidden;
			}
			.cipher-box {
				display: flex;
				border-radius: 0.16rem;
				border: 1px solid #e5e5e5;
				.item {
					flex: 1;
					width: 0;
					height: 1.28rem;
					line-height: 1.28rem;
					font-size: 0.512rem;
					text-align: center;
					border-left: 1px solid #e5e5e5;
					&:first-child {
						border-left: none;
					}
					&.active {
						display: flex;
						justify-content: center;
						align-items: center;
						&:before {
							content: "";
							width: 0.32rem;
							height: 0.32rem;
							display: block;
							border-radius: 50%;
							background-color: #333333;
						}
					}
				}
			}
		}
	}
</style>
